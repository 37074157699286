import React from "react";

const SolarPlant = () => {
  return (
    <>
      <section className="pt-10 pb-10">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="bg-img">
                <img
                  src="assets/images/solar_plant/Earth-poly-anim-2.gif"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-10 pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-10 mb-5">
              <div className="about-content-box pl-lg-5">
                <div className="section-title section-title-left wow fadeInUp">
                  <h2 className="mb-2">Why Choose Solar Power?</h2>
                </div>
                <p className="text-muted fw-600">
                  In today’s world, transitioning to clean and renewable energy
                  is more important than ever. Solar power, harnessed from the
                  sun's energy, is an excellent choice for both individuals and
                  businesses looking to reduce their carbon footprint while
                  enjoying significant cost savings. Here are some key benefits
                  of using solar energy:
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 pl-lg-5">
                <div className="border border-3 border-2px p-4 rounded">
                  <div className="bg-img">
                    <img
                      src="assets/images/solar_plant/eco.webp"
                      alt=""
                      className="img-control"
                      srcset=""
                    />
                  </div>
                  <h4 className="mb-3">Environmentally Friendly</h4>
                  <p className="text-justify fw-600">
                    Solar power is a clean, green source of energy that helps
                    reduce greenhouse gas emissions and our dependence on fossil
                    fuels. By installing a solar plant, you contribute to a
                    healthier planet by minimizing harmful pollutants that lead
                    to climate change and air quality issues.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="border border-3 border-2px p-4 rounded">
                  <div className="bg-img">
                    <img
                      src="assets/images/solar_plant/cost.webp"
                      alt=""
                      className="img-control"
                      srcset=""
                    />
                  </div>
                  <h4 className="mb-3">Cost-Effective Energy</h4>
                  <p className="text-justify fw-600">
                    After the initial setup, solar energy significantly reduces
                    electricity bills. With rising utility prices, a solar plant
                    provides an effective long-term solution to save on energy
                    costs. In some cases, you can even generate excess power,
                    which can be sold back to the grid, further lowering costs.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="border border-3 border-2px p-4 rounded">
                  <div className="bg-img">
                    <img
                      src="assets/images/solar_plant/renewable.webp"
                      className="img-control"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <h4 className="mb-3">Renewable and Abundant</h4>
                  <p className="text-justify fw-600">
                    Solar energy is a renewable resource that we can tap into
                    every day. Unlike finite fossil fuels, the sun provides an
                    inexhaustible supply of energy. This ensures energy security
                    for future generations and a step towards a more sustainable
                    future.
                  </p>
                </div>
              </div>
              <div className="col-md-4 pl-lg-5">
                <div className="border border-3 border-2px p-4 rounded">
                  <div className="bg-img">
                    <img
                      src="assets/images/solar_plant/lifespan.webp"
                      className="img-control"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <h4 className="mb-3">Low Maintenance and Long Lifespan</h4>
                  <p className="text-justify fw-600">
                    Solar panels have a long lifespan—typically 25 to 30
                    years—and require minimal maintenance. Once installed, they
                    silently generate power with little need for ongoing upkeep,
                    making them a hassle-free energy solution.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="border border-3 border-2px p-4 rounded">
                  <div className="bg-img">
                    <img
                      src="assets/images/solar_plant/energy.webp"
                      className="img-control"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <h4 className="mb-3">Energy Independence</h4>

                  <p className="text-justify fw-600">
                    Installing a solar plant allows you to produce your own
                    energy, reducing your dependence on traditional power
                    sources. This energy independence can be particularly
                    important in regions with unstable grids or high energy
                    demand.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="border border-3 border-2px p-4 rounded ">
                  <div className="bg-img">
                    <img
                      src="assets/images/solar_plant/gIncentives.webp"
                      className="img-control"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <h4 className="mb-3">Government Incentives</h4>
                  <p className="text-justify fw-600">
                    Many governments offer tax incentives, rebates, and
                    subsidies to encourage solar energy adoption. These
                    incentives can offset the initial costs of installation,
                    making solar more affordable for homes and businesses.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="product-section">
        <div className="project-grid-page pt-50 ">
          <div className="container text-center">
            <h1 className="heading display-4 font-italic">
              Solar Plant Installation Process
            </h1>
            <img src="assets/images/solar_plant/solar.webp" alt="" srcset="" />
          </div>
        </div>
      </section>
      <section>
        <div className="product-section container">
        
            <div className="project-grid-page pt-50">
              <div className="container text-center">
                <h1 className="heading display-4 font-weight-bold">
                  Subsidy Plans by Government
                </h1>
              </div>
              <table class="table text-center mt-5 mb-5 ">
                <thead>
                  <tr className="table-primary ">
                    <th scope="col">#</th>
                    <th scope="col">Kilo Watt</th>
                    <th scope="col">Approx Prices</th>
                    <th scope="col">Subsidy Claim</th>
                  </tr>
                </thead>
                <tbody>
                  <tr >
                    <th scope="row">1</th>
                    <td>3kw</td>
                    <td>&#8377;180000</td>
                    <td>&#8377;78000</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>5Kw</td>
                    <td>&#8377;250000</td>
                    <td>&#8377;78000</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>6Kw phase</td>
                    <td>&#8377;310000</td>
                    <td>&#8377;78000</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>8Kw phase</td>
                    <td>&#8377;410000</td>
                    <td>&#8377;78000</td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>10Kw</td>
                    <td>&#8377;480000</td>
                    <td>&#8377;780000</td>
                  </tr>
                </tbody>
              </table>
            </div>
         
        </div>
      </section>
    </>
  );
};

export default SolarPlant;
