import React from "react";

import Slider from "./Slider";
import Testimonials from "./Testimonials";
import Services from "./Services";
// import MeetOurCeo from "./MeetOurCeo";
import { FaCheckCircle } from "react-icons/fa";
export default function Home() {
  return (
    <>
      {/* slider */}

      <Slider />

      {/* slider */}

      {/* services */}
      <Services />
      {/* services */}

      <section className="container mx-auto about-section">
        <div className="pl-5 pr-5">
          <div className="row align-items-center mb-5">
            <div className="col-lg-6">
              <div className="about-content-box pl-lg-5">
                <div className="section-title section-title-left mb-4 wow fadeInUp">
                  <h2 className="mb-3">Why Choose Us?</h2>
                </div>
                <ul className="about-li">
                  <li>
                    <FaCheckCircle
                      style={{ color: "#4CAF50", marginRight: "8px" }}
                    />{" "}
                    Dedicated to providing maximum customer satisfaction.
                  </li>
                  <li>
                    <FaCheckCircle
                      style={{ color: "#4CAF50", marginRight: "8px" }}
                    />{" "}
                    Supported by a skilled team of service technicians.
                  </li>
                  <li>
                    <FaCheckCircle
                      style={{ color: "#4CAF50", marginRight: "8px" }}
                    />{" "}
                    Built a strong reputation for reliability and quality.
                  </li>
                  <li>
                    <FaCheckCircle
                      style={{ color: "#4CAF50", marginRight: "8px" }}
                    />{" "}
                    Cultivated a loyal customer base through quality service.
                  </li>
                  <li>
                    <FaCheckCircle
                      style={{ color: "#4CAF50", marginRight: "8px" }}
                    />{" "}
                    Committed to long-term partnerships and addressing customer
                    needs.
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="col-lg-6 mb-4 mb-lg-0"
              style={{ alignContent: "center", textAlign: "center" }}
            >
              <div className="about-image-box wow fadeInLeft">
                <img
                  src="assets/images/about/about.webp"
                  className="img-fluid rounded-pill shadow-lg"
                  alt="Team"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-1">
        <div className="container text-center pb-4 section-title">
          <h2 className="title display-4 font-weight-bold customheading">
            Best Brands for CCTV Cameras, Solar & Batteries
          </h2>

          <h6 className="pt-3 text-muted font-italic">
            Unisafe Digital provides top-quality products from CPPLUS,
            HIKVISION, DAHUA, LUMINOUS.
          </h6>
        </div>

        <section className="pb-5 pt-5">
          <div className="container">
            <div className="row">
              {/* Brand Card 1 */}
              <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                <div className="brand-card text-center shadow-sm p-3 rounded-lg hover-shadow">
                  <img
                    src="assets/images/partner/1.webp"
                    alt="Brand 1"
                    className="img-fluid"
                  />
                </div>
              </div>
              {/* Brand Card 2 */}
              <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                <div className="brand-card text-center shadow-sm p-3 rounded-lg hover-shadow">
                  <img
                    src="assets/images/partner/2.webp"
                    alt="Brand 2"
                    className="img-fluid"
                  />
                </div>
              </div>
              {/* Brand Card 3 */}
              <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                <div className="brand-card text-center shadow-sm p-3 rounded-lg hover-shadow">
                  <img
                    src="assets/images/partner/3.webp"
                    alt="Brand 3"
                    className="img-fluid"
                  />
                </div>
              </div>
              {/* Brand Card 4 */}
              <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                <div className="brand-card text-center shadow-sm p-3 rounded-lg hover-shadow">
                  <img
                    src="assets/images/partner/4.webp"
                    alt="Brand 4"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="product-section">
        <div className="project-grid-page">
          <div className="container text-center">
            <h1 className="heading display-4">Our Vision and Mission</h1>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          <div className="row ">
            <div className="col-lg-4 col-md-6 col-sm-4 mt-4 mission-vision-values">
              <div className="card">
                <img
                  src="assets/images/home/mission.webp"
                  alt="Mission Icon"
                  className="imgicon"
                />
                <h2>Our Mission</h2>
                <p>
                  To provide top-tier solar energy solutions that promote energy
                  independence, reduce carbon footprints, and empower clients to
                  harness the power of renewable energy efficiently.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-4 mt-4 mission-vision-values">
              <div className="card">
                <img
                  src="assets/images/home/vision.webp"
                  alt="vision Icon"
                  className="imgicon"
                />
                <h2>Our Vision</h2>
                <p>
                  To lead the transition to a sustainable future by making solar
                  energy accessible and affordable for everyone.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-4 mt-4 mission-vision-values">
              <div className="card">
                <img
                  src="assets/images/home/values.webp"
                  alt="Values Icon"
                  className="imgicon"
                />
                <h2>Our Values</h2>
                <p>
                  Innovation, reliability, and customer-focused solar solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonials />

      <style jsx>{`
        .customheading {
          font-size: 2.5rem;
          color: #333;
        }

        .brand-card {
          background: #fff;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .brand-card:hover {
          transform: translateY(-10px);
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
        }

        .img-fluid {
          max-width: 100%;
          height: auto;
        }

        .hover-shadow:hover {
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        }

         {
          /*  */
        }

         {
          /*  */
        }

        @media (max-width: 768px) {
          .title {
            font-size: 1.75rem;
          }
        }
      `}</style>
    </>
  );
}
